.inner-content {
  h1 {
    margin-bottom: 45px;
    color: #F3AE00;
  }

  h2, h3, h4 {
    margin-top: 35px;
    margin-bottom: 20px;
  }

  ul {
    margin-left: 45px;
    margin-right: 45px;
    margin-top: 35px;
    margin-bottom: 45px;
  }
}

.text-highlight {
  color: #F3AE00;
}

.actual-table {
  border: 1px solid #555555;

  .table-header-row {
    border: 1px solid #555555;
    background-color: #cccccc;
    .table-header-column {
      padding: 5px 5px 5px 15px;
    }
  }

  .table-content-row {
    border: 1px solid #555555;
    .table-content-column {
      padding: 5px 15px 5px 15px;
      text-align: left;
      vertical-align: top;

    }
  }

}

img {
  max-width: 1027px;
}

@media (max-width: 992px) {
  img {
    max-width: 750px;
  }

}

@media (max-width: 767px) {
  img {
    max-width: 520px;
  }

}

@media (max-width: 550px) {
  img {
    max-width: 290px;
  }

}


.endpoints {
  margin-bottom: 40px;
  margin-top: 40px;
}
.endpoint-type {
  text-align: center;
  font-size: 2.0em;
  color: #F3AE00;
}
.endpoint-to {
  text-align: center;
  font-size: 2.0em;
  color: #555555;
}

.product-container {
  min-height: 400px;
}

.hero-wrapper {
  min-height: 427px;
  background: transparent url('/assets/img/bg-hero.jpg') repeat 0% 0%;

  .hero-inner {
    padding-top: 100px;
    padding-left: 100px;
    .hero-title {
      color: #F3AE00;
      background-color: #585f69;
      padding: 10px 10px 10px 10px;
      max-width: 900px;
    }
  }
}
.code {
  font-family: 'courier';
  margin-top: 45px;
  margin-bottom: 45px;
}
.navbar-header {
  min-height: 45px;
  margin-top: 15px;
}
/* Add here all your css styles (customizations) */
a {
  color: #F3AE00;
}

.blog-item {
	font-size: 1.4em;
}

.blog-item h3 {
	font-size: 1.6em;
}

.blog-item h2 {
	font-size: 1.8em;
}


.blog-item h1 {
	font-size:2.0em;
}
